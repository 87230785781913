import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "./chat.css"
import { EditDp, Footer, Navbar } from "../Components";
import CustomTop from "./CustomTop";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BiSearch } from 'react-icons/bi';
import axiosConfig from "../Service/axiosConfig"
import { MdOutlineArrowBackIos, MdOutlineInfo, MdOutlinePhone, MdOutlineVideocam } from "react-icons/md";
import { BsEmojiSmile } from "react-icons/bs";
import { RiImage2Fill } from "react-icons/ri";
import { TiMicrophoneOutline } from "react-icons/ti";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from '../Utils/AuthContext';
import { UpgradePage } from "./UpgradePage";
import { CraftsModal } from "./CraftsModal";
import { Modal } from "react-bootstrap";
const autoLink = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
};
const MessagesPage = () => {
    let { id } = useParams();
    const textareaRef = useRef(null);
    const location = useLocation();
    const { data } = location.state || {};
    const isMobile = window.innerWidth <= 767.99;
    const showChatList = isMobile && location.pathname === '/messages';
    const showChatWindow = isMobile && location.pathname.includes('/messages') && location.pathname !== '/messages';
    let navigate = useNavigate()
    const { isLoggedIn, is24Craft, socketUrl, messagelabel, setMessagelabel, setCounts } = useAuth();
    let profile_data = JSON.parse(localStorage.getItem("profile_data"))
    const [selectedChat, setSelectedChat] = useState({});
    const [chatlist, setChatlist] = useState([])
    const [filteredChatlist, setFilteredChatlist] = useState([]);
    const [message, setMessage] = useState('');
    const [chat, setChat] = useState([]);
    const [receiverId, setReceiverId] = useState("")
    const [search, SetSearch] = useState("")
    const textContainerRef = useRef(null);
    const [showApply, setshowApply] = useState(false)
    const [profilePic, setProfilepic] = useState(false)
    const [UpgradeShow, setUpgradeShow] = useState(false);
    const [ScreenVisbile, setScreenVisbile] = useState("plans");
    const [scroll, setscroll] = useState(false)
    const [NextPage, setNextPage] = useState(null)
    const [page, setPage] = useState(1);
    const [count, setCount] = useState()
    const [selectedTab, setSelectedTab] = useState("all");
    const [MessageCount, setMessagecount] = useState(0)
    const handleUpgradeClose = () => {
        setUpgradeShow(false);
        setScreenVisbile("plans");
    };
    const handleprofilpicclose = () => {
        setProfilepic(false)
    }
    const handleClose = () => {
        setshowApply(false)
    }
    const HandleProfilepic = () => {
        if (profile_data?.profile_pic === null) {
            setProfilepic(true)
        } else if (localStorage.getItem('is_24_craft') === null) {
            setshowApply(true)
        }
        else {
            setUpgradeShow(true);
        }
    }
    const UpgradeCheck = (id) => {
        if (localStorage.getItem('token')) {
            if (localStorage.getItem('is_pro') === "true") {
                sendMessage()
            } else {
                HandleProfilepic()
            }
        } else {
            document.querySelector('.login-text').click();
        }
    };
    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        const now = new Date();
        const isDifferentDay = date.toDateString() !== now.toDateString();
        if (isDifferentDay) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            return `${day}/${month}`;
        } else {
            const options = {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            };
            const formattedTime = date
                .toLocaleTimeString('en-US', options)
                .toLowerCase()
                .replace(' ', '');
            return formattedTime;
        }
    };
    function timeAgo(dateString) {
        const pastDate = new Date(dateString);
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
        const formattedTime = pastDate
            .toLocaleTimeString('en-US', options)
            .toLowerCase()
            .replace(' ', '');
        return formattedTime;
    }
    const MarkAsRead = async (chatId) => {
        const formData = new FormData();
        formData.append('receiver', chatId);
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        try {
            const response = await axiosConfig.post('/messagesapp/messages/mark_as_read/', formData);
            console.log("saved", response)
            MessagesList()
        } catch (error) {
            console.log(error);
        }
    }
    const handleChatSelection = (Data) => {
        console.log(Data)
        const chatId = Data?.user_id
        setSelectedChat(Data);
        setReceiverId(chatId)
        navigate(`/messages/${chatId}`);
        MarkAsRead(chatId)
    };
    const MessagesList = async () => {
        try {
            const responce = await axiosConfig.get(`/messagesapp/messages/chat_summary/`)
            setChatlist(responce.data)
            updateFilteredChatlist(responce.data, selectedTab)
            const unreadcount = responce.data?.filter(item => item.unread_count > 0)?.length;
            console.log(unreadcount, "test")
            setMessagecount(unreadcount)
            Messagescounts()
        }
        catch (error) {
            console.log(error)
        }
    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const value = e.target.value;
        SetSearch(value);
        const filtered = chatlist.filter(chat =>
            chat?.first_name?.toLowerCase().includes(value.toLowerCase()) ||
            chat?.last_name?.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredChatlist(filtered);
    };
    const fetchMessages = async (id) => {
        try {
            const response = await axiosConfig.get(`/messagesapp/messages/get_messages/?receiver=${id}&page=${page}`)
            setCount(Math.ceil(response.data.count / 20));
            setNextPage(response.data.next);
            const MessageData = response.data.results
            MessageData.sort((a, b) => b.sort - a.sort);
            MessageData.reverse();
            setChat(MessageData);
            // ScrollBottom()
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };
    const sendMessage = async () => {
        if (message) {
            socketUrl.emit('message', { receiver: receiverId, content: message, sender: profile_data?.id });
            const formData = new FormData();
            formData.append('receiver', receiverId);
            formData.append('content', message);
            axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
            try {
                const response = await axiosConfig.post('/messagesapp/messages/send_message/', formData);
                console.log("saved", response)
                MessagesList()
                fetchMessages(receiverId)
            } catch (error) {
                console.log(error);
            }
            setMessage('');
        }
    };
    const handleApiNextPage = async (id, number) => {
        try {
            const response = await axiosConfig.get(`/messagesapp/messages/get_messages/?receiver=${id}&page=${number}`);
            setNextPage(response.data.next)
            const MessageData = response.data.results
            MessageData.sort((a, b) => b.sort - a.sort);
            MessageData.reverse();
            const combinedData = [...MessageData, ...chat];
            setChat(combinedData)
        } catch (error) {
            console.log(error);
        }
    }

    const getIdFromUrl = () => {
        const path = window.location.pathname; // Example: "/item/123"
        const segments = path.split('/'); // Split into segments
        const id = segments[segments.length - 1]; // Get the last segment
        return id; // "123"
    };
    const Messagescounts = async () => {
        try {
            const response = await axiosConfig.get(`/accounts/user-profiles/${profile_data?.profile_id}/unread_messages_count/`)
            const unreadMessages = response.data?.unread_messages;
            if (response.data.unread_messages) {
                setCounts(prevCounts => ({
                    ...prevCounts,
                    message_count: unreadMessages,
                }))
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        socketUrl?.on('message', (data) => {
            console.log("New message received", data)
            if (JSON.stringify(data.sender) === getIdFromUrl()) {
                const DisplayData = {
                    sender: data.sender,
                    receiver: data.receiver,
                    content: data.content,
                    senderprofile_pic: selectedChat?.profile_pic
                }
                console.log(DisplayData)
                setChat((prevChat) => [...prevChat, DisplayData]);
                MarkAsRead(data.sender)
            } else {
                setMessagelabel(true)
            }
            Messagescounts()
            MessagesList()
        });
        return () => socketUrl?.off('message');
    }, [receiverId]);
    useEffect(() => {
        MessagesList()
    }, [])
    useEffect(() => {
        if (id) {
            fetchMessages(id);
        }
    }, [id]);
    useEffect(() => {
        if (data) {
            console.log(data, "data")
            const selectedData = {
                first_name: data?.user?.first_name ? data?.user?.first_name : data?.first_name,
                last_name: data?.user?.last_name ? data?.user?.last_name : data?.last_name,
                user_id: data?.user?.id ? data?.user?.id : data?.user_id,
                user_name: data?.user?.email ? data?.user?.email : data?.first_name,
                hcc_id: data.hcc_id,
                is_verified: data?.is_verified,
                profile_pic: data?.profile_pic,
            };
            setSelectedChat(selectedData)
            setReceiverId(data?.user?.id ? data?.user?.id : data?.user_id)
        }
    }, [data])
    useEffect(() => {
        if (id && chatlist.length > 0) {
            const selectedChat = chatlist.find(chat => chat.user_id === parseInt(id));
            if (selectedChat) {
                setSelectedChat(selectedChat);
                setReceiverId(selectedChat.user_id);
            }
        }
    }, [id, chatlist]);
    const handleScroll = (e) => {
        const target = e.target;
        const top = target.scrollTop;  // Corrected property name
        if (top === 0) {
            if (!NextPage) {
                return null
            }
            else {
                setscroll(true)
                if (page < count) {
                    setscroll(true)
                    setPage((prevPage) => prevPage + 1);
                    handleApiNextPage(id, page + 1);
                }
                console.log("ScrollTop", top)
            }
        }
    };

    useLayoutEffect(() => {
        if (!scroll) {
            scrollToBottom();
        }
        setscroll(false)
    }, [chat, id]);
    const scrollToBottom = () => {
        if (textContainerRef.current !== null) {
            textContainerRef.current.scrollTop = textContainerRef.current.scrollHeight;
        }
    };
    const DisplayDateLine = (currentIndex, chatLog) => {
        if (currentIndex > 0) {
            const currentMessageDate = new Date(chatLog[currentIndex].timestamp);
            const previousMessageDate = new Date(chatLog[currentIndex - 1].timestamp);
            const isSameDay = (date1, date2) => {
                return date1.getFullYear() === date2.getFullYear() &&
                    date1.getMonth() === date2.getMonth() &&
                    date1.getDate() === date2.getDate();
            };
            if (!isSameDay(currentMessageDate, previousMessageDate)) {
                if (isSameDay(currentMessageDate, new Date())) {
                    return { displayLine: true, date: 'Today' };
                } else {
                    const formattedDate = `${String(currentMessageDate.getDate()).padStart(2, '0')}-${String(currentMessageDate.getMonth() + 1).padStart(2, '0')}-${currentMessageDate.getFullYear()}`;
                    return { displayLine: true, date: formattedDate };
                }
            }
        }
        return { displayLine: false, date: null };
    };
    const updateFilteredChatlist = (chats, tab) => {
        if (tab === "unread") {
            setFilteredChatlist(chats.filter((chat) => chat.unread_count > 0));
        } else if (tab === "request") {
            setFilteredChatlist(chats.filter((chat) => chat.is_accepted === null || false));
        } else {
            setFilteredChatlist(chats);
        }
    };
    const handleTabChange = (tab) => {
        setSelectedTab(tab);
        updateFilteredChatlist(chatlist, tab); // Update filtered list when tab changes
    };
    const AcceptMessageRequest = async (Data) => {
        const requestData = {
            sender: Data?.user_id,
            receiver: profile_data.id,
            is_accepted: true
        };
        try {
            const response = await axiosConfig.post(`/messagesapp/message-requests/`, requestData);
            MessagesList()
        } catch (error) {
            console.error("Error while sending PATCH request:", error.response || error.message);
        }
    }
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "auto"; // Reset height
            textareaRef.current.style.height = `${Math.min(
                textareaRef.current.scrollHeight,
                72 // 3 rows max height (assuming 24px per row)
            )}px`;
        }
    }, [message]);
    return (
        <>
            <CustomTop />
            <div className="search-screen-container search-page">
                <Navbar />
                <div className="content-area chat-container">
                    <div className={id ? "hidden chat-list" : "chat-list"}>
                        <h4><MdOutlineArrowBackIos size={20} onClick={() => navigate(-1)} /> All Chats</h4>
                        <div className="searchbar">
                            <input type="search" placeholder="Search for people" name="search" value={search} onChange={HandleSearch} />
                            <BiSearch size={25} color='#9D9D9D' />
                        </div>
                        <div className='chat-tabs'>
                            <div className={`${selectedTab === "all" ? "chat-type" : "chat-deselect"}`} onClick={() => handleTabChange("all")}>All</div>
                            <div className={`${selectedTab === "unread" ? "chat-type" : "chat-deselect"}`} onClick={() => handleTabChange("unread")}>Unread ({MessageCount})</div>
                            <div className={`${selectedTab === "request" ? "chat-type" : "chat-deselect"}`} onClick={() => handleTabChange("request")}>Requests</div>
                        </div>
                        <div className="chat-items">
                            {filteredChatlist.map((chat, index) => (
                                < div
                                    key={index}
                                    className={`chat-item ${parseInt(id) === chat?.user_id ? 'active' : ''}`}
                                    onClick={() => handleChatSelection(chat)}
                                >
                                    <img src={chat?.profile_pic ? chat?.profile_pic : require("../Components/Post/images/male.jpg")} alt="profile-pic" width="40" className="chat-profile-pic" />
                                    <div className="chat-details">
                                        <h5>{chat?.first_name}</h5>
                                        <p>{chat?.last_message}</p>
                                    </div>
                                    <div className="chat-meta">
                                        <span className="time">{formatTime(chat?.timestamp)}</span>
                                        {chat?.unread_count > 0 && <span className="unread-count">{chat?.unread_count}</span>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={id ? "chat-window" : "message-dummy-content hidden"}>
                        {!id ? (
                            <div className="no-chat-selected">
                                <img src={require("./images/dummy-message-symbol.png")} />
                                <h2>Your Messages</h2>
                                <p>Send a message to start a chat.</p>
                            </div>
                        ) : (
                            <>
                                <div className="chat-header">
                                    <MdOutlineArrowBackIos size={20} onClick={() => navigate(-1)} />
                                    <div className="user-info" onClick={() => navigate(`/profile/${selectedChat?.hcc_id}`)}>
                                        <div className='chat-image'>
                                            {/* <div className='chat-dot'></div> */}
                                            <img src={selectedChat.profile_pic ? selectedChat?.profile_pic : require("../Components/Post/images/male.jpg")} alt={selectedChat?.first_name} className="chat-profile-pic" />
                                        </div>
                                        <div>
                                            <div className='header-name' >{selectedChat?.first_name}</div>
                                            <p className="status">{selectedChat?.hcc_id}</p>
                                        </div>
                                    </div>
                                    <div className="chat-actions">
                                        {/* <MdOutlinePhone size={30} />
                                <MdOutlineVideocam size={32} />
                                <MdOutlineInfo size={32} /> */}
                                    </div>
                                </div>
                                <div className="chat-body" ref={textContainerRef} onScroll={handleScroll}>
                                    <div className='chat_messages_scroll'>
                                        {chat.map((msg, index) => {
                                            return (
                                                <>
                                                    {DisplayDateLine(index, chat).displayLine && (
                                                        <div className="date-display">
                                                            <span className='hr-text'>{DisplayDateLine(index, chat).date}</span>
                                                        </div>
                                                    )}

                                                    <div key={index} className={`chat-message ${msg.sender?.id === profile_data?.id ? 'sent' : 'received'}`} >
                                                        <div className="message-content" >
                                                            {msg.sender.id !== profile_data?.id && <img src={msg.sender?.profile_pic ? msg.sender?.profile_pic : msg?.senderprofile_pic} className="profile-pic-small" alt={`${msg?.sender?.first_name} ${msg?.sender?.last_name}`} />}
                                                            <div className="message-timer">
                                                                <div className="message-text" ><p dangerouslySetInnerHTML={{ __html: autoLink(msg.content) }} /></div>
                                                                <div className="msg-time">{timeAgo(msg?.timestamp)}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="message-input" >
                                    <textarea ref={textareaRef} rows={1} placeholder="Type here..." value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={(e) => e.key === "Enter" && UpgradeCheck()}></textarea>
                                    {message !== "" && <div className="send-button send-position" onClick={UpgradeCheck} >Send</div>}
                                </div>
                                {/* {selectedChat.is_accepted === true ? (
                                    <div className="message-input" >
                                        <BsEmojiSmile size={27} />
                                        <input type="text" placeholder="Type here..." rows="1" value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={(e) => e.key === "Enter" && UpgradeCheck()} />
                                        <RiImage2Fill size={30} />
                                        <TiMicrophoneOutline size={30} />
                                        {message !== "" && <div className="send-button send-position" onClick={UpgradeCheck} >Send</div>}
                                    </div>
                                ) : (
                                    <>
                                        <div className="Request-message">
                                            <div className="Accept-text">Accept message request from <strong>{selectedChat?.first_name}</strong>?</div>
                                            <p className="Accept-content">If you accept, You will receive the messages directly to your inbox.</p>
                                            <div className="request-buttons">
                                                <div className="" onClick={() => AcceptMessageRequest(selectedChat)}>Accept</div>
                                            </div>
                                        </div>
                                    </>
                                )} */}
                            </>
                        )}
                    </div>
                </div >
            </div >
            {/* <Footer /> */}
            {
                UpgradeShow &&
                <UpgradePage UpgradeShow={UpgradeShow} setUpgradeShow={setUpgradeShow} ScreenVisbile={ScreenVisbile} setScreenVisbile={setScreenVisbile} handleUpgradeClose={handleUpgradeClose} />
            }
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={profilePic} onHide={handleprofilpicclose} className="edit-profile-popup">
                <Modal.Header closeButton>
                    <Modal.Title>Add your profile to get started</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditDp handleLoginEvent={handleprofilpicclose} showApply={showApply} setshowApply={setshowApply} />
                </Modal.Body>
            </Modal>
            {
                showApply &&
                <CraftsModal showApply={showApply} setshowApply={setshowApply} handleClose={handleClose} />
            }
        </>
    );
}

export { MessagesPage };