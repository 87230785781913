import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig"
import { useNavigate, useSearchParams, NavLink, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Masters from "../Components/masters.json"
import { FormParser, PopupModal } from "../Components";

const AuditionRequirements = ({ requirementShow, setRequirementShow, dataid }) => {
    // const [requirementShow, setRequirementShow] = useState(false)
    const [popupType, setpopupType] = useState("");
    let navigate = useNavigate()
    const HandlePopupClose = () => {
        setRequirementShow(false)
        navigate(`/auditions/${dataid}`)
    }
    const AudiRequirements = (data, setFieldErrors) => {
        console.log(data)
        const orderTopost = {
            ...data,
            audition: dataid
        }
        if (parseInt(data?.from_age, 10) >= parseInt(data?.to_age, 10)) {
            let errorMessage = "From age must be less than To age."
            setFieldErrors(prev => ({ ...prev, ['from_age']: errorMessage, ['to_age']: errorMessage }));
        } else {
            axiosConfig.post("/accounts/audition-requirements/", orderTopost).then(res => {
                setpopupType("success")
            }).catch(error => {
                console.log(error.response.data)
                setpopupType("error");
            })
        }
    }
    return (
        <>
            <Modal
                size="lg"
                show={requirementShow}
                onHide={() => setRequirementShow(false)}
                aria-labelledby="audition-requirements-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="audition-requirements-title">
                        Add Audition Requirements
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={Masters.AditionRequirements} formSubmit={AudiRequirements} error={Error} />
                </Modal.Body>
            </Modal>
            {popupType !== "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} error={Error} HandleEvents={HandlePopupClose} />
            }
        </>
    )
}
export { AuditionRequirements };